import create from 'zustand';

/*

Data Routes:
----------------------

URI patterns to match and manipualte store state data

*/

const routes = [
    {
      route:'(.*)/?',
      data : (matches,state)=> {

        return {SHOWGALLERY:false}
      }
    },
    {
      route:'^/$',
      data : (matches,state)=> {

        return {SHOWPROGRAMME:false,SHOWINFO:false}
      }
    },
    {
        route:'programme/?',
        data : (matches,state)=> {
          
          return {SHOWPROGRAMME:true}

        }
  	},
  	{
        route:'info/?',
        data : (matches,state)=> {
          
          return {SHOWINFO:true}

        }
  	}
  ]

/*

Data Transformer:
----------------------

State properties we will be using through site
Returns an object which will be merged into state

*/

const transformer = (data,set,get) => {

	// Make an entry from each raw data item
	const ENTRIES = data.map(e=>makeEntry(e));


	// Cache key pages
	const HOME = ENTRIES.find(i=>i.type === 'home');
	const INDEX = ENTRIES.find(i=>i.type === 'index');
	const PROFILE = ENTRIES.find(i=>i.type === 'profile');

	// Expand tile URIs
	/*const TILES = HOME.tiles.map(
		t => {

			let tile = {...t};

			let link = ENTRIES.find(e=>e.id === t.link.id);
	
			tile.uri = link ? link.uri : null;

			return tile;

		}
	);*/

	// Expand index URIs
	/*const PROJECT_INDEX = INDEX.projects.map(
		p => {

			let project = {...p};

			let link = ENTRIES.find(e=>e.id === p.link.id);
		
			project.uri = link ? link.uri : null;

			return project;

		}
	);*/

	return {

		HOME,INDEX,PROFILE,ENTRIES,ACTIVE_TILE:null,
		setActiveTile: (media) => {
			console.log('active tile',media);
			if(typeof media !== 'undefined'){
    		return set({ACTIVE_TILE:media});
    		}else{
    			return null;
    		}
  		}

	}

}

/*

Transform raw data into entries with consistent properties

*/

function makeEntry(item){

	let entry = {...item.data,type:item.type,id:item.id,uid:item.uid,createdDate : item.first_publication_date, publishDate : item.last_publication_date}

	let uri = (item.uid && (item.uid !== item.type)) ? `/${item.type}/${item.uid}/` : `/${item.type}/`;

	if(item.type === 'home'){
		uri = '/';
	}

	if(!item.data.hasOwnProperty('title')){
		entry.title = capitalize(item.type);
	}
	
	return {
		...entry,
		uri
	};

}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}


export {transformer,routes}