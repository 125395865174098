import React , {useEffect,useState} from 'react'


export const Tooltip = ({className,children}) => {
   const [position, setPosition] = useState({x: 0, y: 0});

   useEffect(() => {

      const addEventListeners = () => {
           document.addEventListener("mousemove", onMouseMove);
       };

       const removeEventListeners = () => {
           document.removeEventListener("mousemove", onMouseMove);
       };

       const onMouseMove = (e) => {
           setPosition({x: e.clientX, y: e.clientY});
       }; 
       addEventListeners();
       return () => removeEventListeners();
   }, []);

                                                                 


   return <div className={className || 'Tooltip'}
           style={{
               left: `${position.x}px`,
               top: `${position.y}px`
           }}>{children}</div>
}
