
const propSort = function(arr,prop, direction){
    
    let dirLabel = direction || 'asc'; // 'desc' or 'asc'

    let direct = 1; // Default to ascending

    if(dirLabel === 'desc') direct = -1;


    if(typeof prop != 'string') return;
    
    let propPath = (prop.constructor===Array) ? prop : prop.split(".");

    arr.sort(function(a,b){
        
       
        
        for (var p in propPath){
            if (a[propPath[p]] && b[propPath[p]]){
                a = a[propPath[p]];
                b = b[propPath[p]];
            }
        }
        
         // Convert to string
        a = a+'';
        b = b+'';
        
        // convert numeric strings to integers
        a = a.match(/^\d+$/) ? +a : a;
        b = b.match(/^\d+$/) ? +b : b;

        return ( (a < b) ? -1*direct : ((a > b) ? 1*direct : 0) );
    });
    
    return arr;
}

export const Sort = ({items,children,dir,prop}) => {
  
  let sorted = propSort(items,prop,dir);

  return children({items:sorted});

}
