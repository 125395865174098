import React, { useState, useEffect, useRef } from 'react';

export const StackGallery = ({items,children,}) => {

    const [i,setI] = useState(0);
    const timer = useRef(false);
    const count = useRef(0);

    useEffect(()=>{
      if(!timer.current){

        timer.current = setInterval(()=>{
          let newI = count.current + 1;
          if(newI >= items.length) newI = 0;

          count.current = newI;
          setI(newI);
        },1000);
      }
    },[]);

    let activeStyle = {opacity:1};
    let inactiveStyle = {opacity:0};

    return items.map((item,ix)=>{
      return <div style={(ix <= count.current) ? activeStyle : inactiveStyle}>{children(item,ix)}</div>
    })
  
 }