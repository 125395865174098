
const propGroup = function(arr,prop){
    
  return arr.reduce((memo,item) => {
      (memo[item[prop]] = memo[item[prop]] || []).push(item);
      return memo;
    }, {});
}

export const Group = ({items,children,dir,prop}) => {
  
  let groups = propGroup(items,prop);
  let keys = Object.keys(groups);
  let arr = keys.map(k => groups[k]);

  return children({items:arr,keys:keys,groups:groups});

}
