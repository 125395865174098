import React from 'react'
import ReactTicker from 'react-ticker'
 
export const Ticker = ({children,direction=null}) => (
    <ReactTicker direction={direction}>
        {({ index }) => (
            <>
                {children}
            </>
        )}
    </ReactTicker>
)
 